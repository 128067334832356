import { uiElements } from "./uiElements";

export const IosPlayer = {
  initialize() {
    if(!window.prerollAudioSource) this.play();
    uiElements.ensureElementsAreUpdated();
  },

  play() {
    webkit.messageHandlers.Native.postMessage(this.playCommand());
    uiElements.toggleVisibility(window.pauseButtons, window.playButtons);
  },

  stop() {
    webkit.messageHandlers.Native.postMessage(this.stopCommand());
    uiElements.toggleVisibility(window.playButtons, window.pauseButtons);
  },

  playCommand() {
    return JSON.stringify({ play: window.showVideo ? window.videoSource : window.audioSource });
  },

  stopCommand() {
    return JSON.stringify({ stop: window.showVideo ? window.videoSource : window.audioSource });
  }
}
