import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["contactForm", "backgroundOverlay"];

  showContactForm() {
    this._showElement(this.contactFormTarget, ["hidden", "animate-to-top"], ["animate-from-top"]);
    this._showElement(this.backgroundOverlayTarget, ["hidden", "fade-out"], ["fade-in"]);
  }

  hideContactForm() {
    this._hideElement(this.contactFormTarget, ["animate-from-top"], ["animate-to-top"]);
    this._hideElement(this.backgroundOverlayTarget, [], ["fade-out"]);
  }

  _showElement(element, removeClasses, addClasses) {
    removeClasses.forEach(cls => element.classList.remove(cls));
    addClasses.forEach(cls => element.classList.add(cls));
  }

  _hideElement(element, removeClasses, addClasses) {
    removeClasses.forEach(cls => element.classList.remove(cls));
    addClasses.forEach(cls => element.classList.add(cls));
  }
}
