import Hls from "hls.js";
import { uiElements } from "./uiElements";
import { VolumeControls } from "./VolumeControls";
import { VideoOnBackground } from "./VideoOnBackground";

export const VideoPlayer = {
  initialize() {
    VolumeControls.initialize();
    if(!window.prerollAudioSource) this.play();
    this.setFullscreenOnLandscape();
    uiElements.updateElementsBasedOnVideoState();
    VideoOnBackground.initialize();
  },

  play() {
    if (VideoOnBackground.isPausedManually) { VideoOnBackground.isPausedManually = false; }
    const hls = new Hls();
    Hls.isSupported() ? this.playWithHls(hls) : this.playWithMpeg();
  },

  stop() {
    window.video.pause();
    VideoOnBackground.isPausedManually = true;
  },

  fullscreen() {
    window.video.webkitEnterFullscreen();
  },

  playWithHls(hls) {
    hls.loadSource(window.videoSource);
    hls.attachMedia(window.video);
    hls.on(Hls.Events.MANIFEST_PARSED, function () { window.video.play() });
  },

  playWithMpeg() {
    window.video.src = window.videoSource;
    window.video.addEventListener("loadedmetadata", function () { window.video.play() });
  },

  setFullscreenOnLandscape() {
    window.addEventListener("orientationchange", () => {
      window.screen.orientation.angle === 0 ? window.video.webkitExitFullscreen() : this.fullscreen();
    });
  }
}
