import { Controller } from "@hotwired/stimulus";
import { VideoOnBackground } from "../utils/VideoOnBackground";

export default class extends Controller {
  static targets = ["selector", "icon"];

  toggleSelector() {
    this.selectorTarget.classList.remove("hidden");
    this.selectorTarget.classList.toggle("animate-out");

    this.iconTargets.forEach(icon => {
      icon.classList.toggle("hidden");
      icon.classList.add("animate-in");
    });
  }

  changeStation({ params: { url } }) {
    this.prepareChanging(url);
    setTimeout(() => { location.reload(); }, 400);
  }

  prepareChanging(url) {
    window.changingStations = true;
    this.allowFullPageReload();
    this.prepareAllTurboPermanentToBeReloaded();
    this.prepareNewCurrentStation(url);
    this.stopMedia();
  }

  stopMedia() {
    if (window.showVideo) {
      window.player.stop();
      VideoOnBackground.removeVisibilityChangeListener();
    } else if (window.isIosApp) {
      window.player.stop();
    }
  }

  allowFullPageReload() {
    let metaTag = document.createElement("meta");
    metaTag.name = "turbo-visit-control";
    metaTag.content = "reload";
    document.head.appendChild(metaTag);
  }

  prepareAllTurboPermanentToBeReloaded() {
    let turboPermanentElements = document.querySelectorAll("[data-turbo-permanent]");
    turboPermanentElements.forEach(element => { element.removeAttribute("data-turbo-permanent") });
  }

  prepareNewCurrentStation(url) {
    fetch(url);
  }
}
