import { Controller } from "@hotwired/stimulus";
import { VolumeControls } from "../utils/VolumeControls";
import { WebPlayer } from "../utils/WebPlayer";
import { VideoPlayer } from "../utils/VideoPlayer";
import { AndroidPlayer } from "../utils/AndroidPlayer";
import { IosPlayer } from "../utils/IosPlayer";
import { Preroll } from "../utils/Preroll";

export default class extends Controller {
  static targets = [
    "audio",
    "video",
    "playButton",
    "pauseButton",
    "muteButton",
    "unmuteButton",
    "activateSoundButton",
    "volumeSlider",
  ];

  static values = {
    audioSource: String,
    videoSource: String,
    logoUrl: String,
    stationName: String,
    slogan: String,
    isAndroidApp: Boolean,
    isIosApp: Boolean,
    isMobile: Boolean,
    showVideo: Boolean,
  };

  initialize() {
    if (this.hasAudioTarget) {
      this.addValuesToWindow();
      this.setPlayer();
      this.initializePlayer();
    }
  }

  initializePlayer() {
    if (window.prerollAudioSource) { window.activateSoundButton?.classList.add("hidden"); }
    window.player.initialize();
  }

  play() {
    if (window.prerollAudioSource) {
      Preroll.play();
    } else {
      window.player.play();
    }
  }

  pause() {
    window.player.stop();
  }

  mute() {
    VolumeControls.muteVolume();
  }

  unmute() {
    VolumeControls.unmuteVolume();
  }

  setVolume(event) {
    const volume = event.target.value / 100;
    VolumeControls.setVolume(volume);
  }

  fullscreen() {
    VideoPlayer.fullscreen();
  }

  setPlayer() {
    window.player = window.showVideo ? VideoPlayer : window.isAndroidApp ? AndroidPlayer : window.isIosApp ? IosPlayer : WebPlayer;
  }

  addValuesToWindow() {
    window.audio = this.audioTarget;
    window.video = this.hasVideoTarget ? this.videoTarget : null;
    window.playButtons = this.playButtonTargets;
    window.pauseButtons = this.pauseButtonTargets;
    window.muteButtons = this.muteButtonTargets;
    window.unmuteButtons = this.unmuteButtonTargets;
    window.activateSoundButton = this.hasActivateSoundButtonTarget ? this.activateSoundButtonTarget : null;
    window.volumeSliders = this.volumeSliderTargets;
    window.audioSource = this.audioSourceValue;
    window.videoSource = this.videoSourceValue;
    window.logoUrl = this.logoUrlValue;
    window.stationName = this.stationNameValue;
    window.slogan = this.sloganValue;
    window.isAndroidApp = this.isAndroidAppValue;
    window.isIosApp = this.isIosAppValue;
    window.isMobile = this.isMobileValue;
    window.showVideo = this.hasVideoTarget;
  }
}
